import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import moment from "moment";
import helpers from "customs/helpers/helpers";

class ChangeApplicationStatusDialog extends React.Component {

    constructor (props) {

        super(props);

        let significant_name = this.setSignificantName(props.renderValue('significant_name'));
        if (significant_name === null || significant_name.length === 0) {
            significant_name = this.setSignificantName(props.renderValue('legal_name'));
        }
        let significant_name_status = props.renderValue('significant_name_accepted');
        if (significant_name_status !== null && significant_name_status.length === 0) {
            significant_name_status = null;
        } else {
            significant_name_status = {
                "success": "success",
                "status": "ok",
                "in_use": significant_name_status === 1 || significant_name_status === '1' ? false : true,
            }
        }

        this.state = {
            "previous_status": props.application_status,
            "current_status": props.current_status ? props.current_status : props.application_status,
            "btnDisabled": props.btnDisabled,
            "message": props.message,
            "additional_fields_values": props.additional_fields_values,
            "message_text": "Message (Optional)",
            "changeStatus": props.applicationView, //changeStatus,
            "selected_request_fields": props.selected_request_fields,
            "verified_fields": props.verified_fields,
            "request_fields": props.request_fields,
            "approved_signatory_type": props.approved_signatory_type,
            "approved_signatory_email": props.approved_signatory_email,
            "approved_signatory_name": props.approved_signatory_name,

            // "processing_form_file_name": "Webgate_Merchant_Processing_Agreement_",
            "processing_form_file_name": "Nexxtap_Merchant_Processing_Agreement_",

            "significant_name": significant_name,
            "significant_name_status": significant_name_status,
            "significant_name_edited": significant_name,
            "significant_name_edit_on": false,

            "accepts_actions": this.getActions(props, false),
            "gw_payment_methods": this.getPaymentMethods(props, false),
            "accepts_currencies": this.getCurrencies(props, false),
            "client_modules": this.getEnabledModules(props, false),
            
            "edit_field": false,
            "edit_field_key": "",
            "edit_field_key_value": "",

            "checkStatusTimer": ''
        };
    }

    getSignificantName() {
        let name = this.props.renderValue('significant_name');
        if (!name || name.length === 0) {
            name = this.props.renderValue('legal_name');
        }
        if (!name || name.length === 0) {
            name = this.props.renderValue('merchant_name');
        }

        return this.setSignificantName(name);
    }

    setSignificantName(value) {
        value = value.replace(/[^0-9a-z]/gi, '').toUpperCase().substring(0, 8);
        return value;
    }

    handleChange = (value) => {
        if (value !== this.state.current_status) {
            this.setState({"current_status" : value});
            this.props.updateChangeStatusState(value, this.state.additional_fields_values, this.state.message, this.state.approved_signatory_type, this.state.approved_signatory_email, this.state.approved_signatory_name);
        }
        let btnDisabled = this.state.previous_status === value;
        if ((value === 'not_suitable' || value === 'declined') && this.state.message.toString().length === 0) btnDisabled = true;
        this.setState({
            "btnDisabled" : btnDisabled,
            "message_text": value === 'not_suitable' || value === 'declined' ? 'Message (Required)' : 'Message (Optional)',
        });
        this.props.handleButtonState(btnDisabled);
        this.updateUI();
    };

    componentWillReceiveProps(props) {
        if (props.applicationView !== this.state.changeStatus) { // if (props.changeStatus !== this.state.changeStatus) {
            if (props.application_status !== this.state.current_status || (props.select_new_status && props.select_new_status.length > 0)) {
                let current_status = props.select_new_status && props.select_new_status.length > 0 ? props.select_new_status : props.application_status;
                this.setState({
                    "previous_status" : props.application_status,
                    "current_status" : current_status,
                    "btnDisabled" : current_status === props.application_status
                });
                this.props.handleButtonState(current_status === props.application_status);
            }
            if (props.available_fields) {
                let additional_fields_values = [];
                props.available_fields.forEach(element => {
                    additional_fields_values[element.registry_key] = this.state.additional_fields_values.hasOwnProperty(element.registry_key)
                        ? (this.state.additional_fields_values[element.registry_key] === 0 ? 0 : 1) : 0 ;
                });
                this.setState({"additional_fields_values": props.applicationView/*changeStatus*/ ? additional_fields_values : []});
            }
            if (this.props.applicationView/*changeStatus*/ !== props.applicationView/*changeStatus*/ && props.applicationView/*changeStatus*/ === true)
                this.setState({"message": ""});

            if (props.applicationView/*changeStatus*/ === false) {
                this.setState({
                    "selected_request_fields": [],
                    "approved_signatory_type": 'bo1',
                    "approved_signatory_email": '',
                    "approved_signatory_name": '',
                });
            } else {
                // if (props.request_fields && this.state.selected_request_fields.length === 0) {
                //     let selected_request_fields = [];
                //     props.request_fields.forEach((field, index) => {
                //         if (field.checked) {
                //             selected_request_fields.push(field.key);
                //         } 
                //     });
                //     this.setState({"selected_request_fields": selected_request_fields});
                // }
            }
            this.setState({
                "current_status": props.current_status,
                "additional_fields_values": props.additional_fields_values,
                "message": props.message
            });
            this.props.updateChangeStatusState(this.state.current_status, this.state.additional_fields_values, this.state.message, this.state.approved_signatory_type, this.state.approved_signatory_email, this.state.approved_signatory_name);
        }
        this.setState({
            "selected_request_fields": props.selected_request_fields ? props.selected_request_fields : this.state.selected_request_fields,
            "request_fields": props.request_fields ? props.request_fields : this.state.request_fields,
            "verified_fields": props.verified_fields ? props.verified_fields : this.state.verified_fields,
        });
        this.setState({"changeStatus": props.applicationView/*changeStatus*/});
    }

    updateUI() {
        // TODO
    }

    callUpdateStatus(event) {
        this.handleStatus(event);
    }

    handleStatus(event) {
        if (this.state.previous_status !== 'approved' && this.state.current_status === 'approved') {
            if (this.state.significant_name_edit_on && this.state.significant_name_edited !== this.state.significant_name) {
                // this.props.setUsersNotification("Please save changes to 'unique' name first or choose cancel edit.", "danger");
                this.props.setUsersNotification("Please check first if the 'unique' name for the Merchant Area Credentials is available.", "danger");
                return false;
            }

            if (!(this.state.significant_name_status !== null && this.state.significant_name_status.hasOwnProperty('in_use') && (this.state.significant_name_status.in_use === false || this.state.significant_name_status.in_use === 'false'))) {
                this.props.setUsersNotification("Please check first if the 'unique' name for the Merchant Area Credentials is available.", "danger");
                return false;
            }

            if (this.state.accepts_actions.length === 0) {
                this.props.setUsersNotification("Please select at least one action.", "danger");
                return false;
            }
            if (this.state.gw_payment_methods.length === 0) {
                this.props.setUsersNotification("Please select at least one payment method.", "danger");
                return false;
            }
            if (this.state.accepts_currencies.length === 0) {
                this.props.setUsersNotification("Please select at least one currency.", "danger");
                return false;
            }
        }
        this.props.handleStatusChange(this.props.selected_application_id, this.state.current_status, this.state.message, this.state.additional_fields_values, this.state.selected_request_fields, this.state.approved_signatory_type, this.state.approved_signatory_email, this.state.approved_signatory_name);
    }

    allPagesAreVerified() {
        if (this.props.hasFlaggedPage()) {return false;}

        let all_verified = true;
        this.props.verify_pages.forEach(element => {
            if (!this.props.checkIfVerified(element)) all_verified = false;
        });
        return all_verified;
    }

    checkAllBeneficials(checked, index, selected_request_fields) {
        let fields = [
            'request_bo' + index.toString() + '_first_name',
            'request_bo' + index.toString() + '_middle_name',
            'request_bo' + index.toString() + '_last_name',
            'request_bo' + index.toString() + '_date_of_birth',
            'request_bo' + index.toString() + '_position',
            'request_bo' + index.toString() + '_residential_address',
            'request_bo' + index.toString() + '_suburb',
            'request_bo' + index.toString() + '_state',
            'request_bo' + index.toString() + '_post_code',
            'request_bo' + index.toString() + '_mobile_number',
            'request_bo' + index.toString() + '_email'
        ];

        if (checked) {
            fields.forEach((field) => {
                let dKey = field.replace('request_', '');
                let is_disabled = this.state.verified_fields.hasOwnProperty(dKey) && parseInt(this.state.verified_fields[dKey]) === 1;
                if (!is_disabled) {
                    if (!selected_request_fields.includes(field)) {
                        selected_request_fields.push(field);
                    }
                }
            });
        } else {
            fields.forEach((field) => {
                if (selected_request_fields.includes(field)) {
                    selected_request_fields.splice(selected_request_fields.indexOf(field), 1);
                }
            });
        }

        return selected_request_fields;
    }

    areAllBeneficialsChecked(index, selected_request_fields) {
        let fields = [
            'request_bo' + index.toString() + '_first_name',
            'request_bo' + index.toString() + '_middle_name',
            'request_bo' + index.toString() + '_last_name',
            'request_bo' + index.toString() + '_date_of_birth',
            'request_bo' + index.toString() + '_position',
            'request_bo' + index.toString() + '_mobile_number',
            'request_bo' + index.toString() + '_email',
            'request_bo' + index.toString() + '_residential_address',
            'request_bo' + index.toString() + '_suburb',
            'request_bo' + index.toString() + '_state',
            'request_bo' + index.toString() + '_post_code'
        ];

        let all_checked = true;
        fields.forEach((field) => {
            if (!selected_request_fields.includes(field)) {
                all_checked = false;
            }
        });
        let uncheck_field = 'request_bo' + index.toString() + '_beneficial_owner_label';
        if (!all_checked) {
            if (selected_request_fields.includes(uncheck_field)) {
                selected_request_fields.splice(selected_request_fields.indexOf(uncheck_field), 1);
            }
        } else {
            if (!selected_request_fields.includes(uncheck_field)) {
                selected_request_fields.push(uncheck_field);
            }
        }

        return selected_request_fields;
    }

    checkSpecialKeys (key, is_disabled) {
        let mailing_address = ["other_address", "other_suburb", "other_state", "other_post_code"];
        let ubo2 = ["bo2_beneficial_owner_label", "bo2_first_name", "bo2_middle_name", "bo2_last_name", "bo2_date_of_birth", "bo2_position", "bo2_residential_address", "bo2_suburb", "bo2_state", "bo2_post_code", "bo2_mobile_number", "bo2_email"];
        let ubo3 = ["bo3_beneficial_owner_label", "bo3_first_name", "bo3_middle_name", "bo3_last_name", "bo3_date_of_birth", "bo3_position", "bo3_residential_address", "bo3_suburb", "bo3_state", "bo3_post_code", "bo3_mobile_number", "bo3_email"];
        let ubo4 = ["bo4_beneficial_owner_label", "bo4_first_name", "bo4_middle_name", "bo4_last_name", "bo4_date_of_birth", "bo4_position", "bo4_residential_address", "bo4_suburb", "bo4_state", "bo4_post_code", "bo4_mobile_number", "bo4_email"];

        let trust_fields = ["trust_deeds", "trust_name"];
        let merchant_statement_fields = ["recent_merchant_statements_key"];
        let do_you_take_deposit_fields = ["how_long_from_the_time_of_deposit"];
        let subscription_membership_transactions_fields = [ "term_of_your_subscription", "frequency_of_subscriptions", "transaction_information_notes"];

        if (mailing_address.includes(key)) {
            let business_address_not_same_as_mailing = parseInt(this.props.renderValue('business_address_not_same_as_mailing'));
            if (isNaN(business_address_not_same_as_mailing)) business_address_not_same_as_mailing = 1;
            if (business_address_not_same_as_mailing !== 1) {
                return true;
            }
        } else if (trust_fields.includes(key)) {
            let trust_company = parseInt(this.props.renderValue('trust_company'));
            if (isNaN(trust_company)) trust_company = 1;
            if (trust_company !== 1) {
                return true;
            }
        } else if (merchant_statement_fields.includes(key)) {
            let merchant_facilities = parseInt(this.props.renderValue('merchant_facilities'));
            if (isNaN(merchant_facilities)) merchant_facilities = 1;
            if (merchant_facilities !== 1) {
                return true;
            }
        } else if (do_you_take_deposit_fields.includes(key)) {
            let do_you_take_deposit = parseInt(this.props.renderValue('do_you_take_deposit'));
            if (isNaN(do_you_take_deposit)) do_you_take_deposit = 1;
            if (do_you_take_deposit !== 1) {
                return true;
            }
        } else if (subscription_membership_transactions_fields.includes(key)) {
            let subscription_membership_transactions = this.props.renderValue('subscription_membership_transactions');
            if (!subscription_membership_transactions || subscription_membership_transactions.length === 0 || subscription_membership_transactions.toString().toLowerCase() === 'no') subscription_membership_transactions = 0;
            if (subscription_membership_transactions.toString().toLowerCase() === 'yes') subscription_membership_transactions = 1;
            if (isNaN(subscription_membership_transactions)) subscription_membership_transactions = 1;
            if (subscription_membership_transactions !== 1) {
                return true;
            }
        } else if ([...ubo2, ...ubo3, ...ubo4].includes(key)) {
            let number_of_beneficials = parseInt(this.props.renderValue('number_of_beneficials'));
            if (isNaN(number_of_beneficials)) number_of_beneficials = 1;
            if (number_of_beneficials < 2 && ubo2.includes(key)) {
                return true;
            } else if (number_of_beneficials < 3 && ubo3.includes(key)) {
                return true;
            } else if (number_of_beneficials < 4 && ubo4.includes(key)) {
                return true;
            }
        }

        return is_disabled;
    }

    checkSpecialLabels(key, verified_fields) {
        let is_disabled = true;
        let legal_address_label = ['legal_address', 'suburb', 'state', 'post_code'];
        let other_address_label = ['other_address', 'other_suburb', 'other_state', 'other_post_code'];
        let dc_funding_account_name_label = ['dc_funding_account_name', 'dc_funding_bsb', 'dc_funding_account_number', 'dc_funding_bank_name'];
        let dc_branch_address_label = ['dc_branch_address', 'dc_city', 'dc_state', 'dc_post_code'/* , bank_statements_key */];

        let keys = [];
        let force_disabled = false;
        switch (key) {
            case "legal_address_label":
                keys = legal_address_label;
                break;
            case "other_address_label":
                keys = other_address_label;

                let business_address_not_same_as_mailing = parseInt(this.props.renderValue('business_address_not_same_as_mailing'));
                if (isNaN(business_address_not_same_as_mailing)) business_address_not_same_as_mailing = 1;
                if (business_address_not_same_as_mailing !== 1) {
                    force_disabled = true;
                }

                break;
            case "dc_funding_account_name_label":
                keys = dc_funding_account_name_label;
                break;
            case "dc_branch_address_label":
                keys = dc_branch_address_label;
                break;
            default:
               keys = [];
        }
        if (force_disabled) return force_disabled;

        keys.forEach(function(v, k) {
            if (is_disabled) {
                if (!verified_fields.hasOwnProperty(v) || parseInt(verified_fields[v]) !== 1) {
                    is_disabled = false;
                }
            }
        });

        return is_disabled;
    }

    statusCheckTimer = null;
    setStatusCheckCountdownTimer(created_at, last_poll, disable_check_status_button) {
        if (this.statusCheckTimer === null || this.statusCheckTimer === undefined) {
            if (disable_check_status_button) {
                setTimeout(() => {
                    if (!this.isUnmounted) this.setState({'checkStatusTimer': helpers.calculateStatusCheckRemainingTime(created_at, last_poll, moment, false)});
                }, 100);
                this.statusCheckTimer = setInterval(() => {
                    if (!this.isUnmounted) this.setState({'checkStatusTimer': helpers.calculateStatusCheckRemainingTime(created_at, last_poll, moment, false)});
                }, 30000);
            } else {
                if (this.statusCheckTimer !== null) {
                    clearInterval(this.statusCheckTimer);
                }
                if (!this.isUnmounted) setTimeout(() => {this.setState({'checkStatusTimer': ''});}, 100);
            }
        }
    }

    componentWillUnmount () {
        this.isUnmounted = true;
        if (this.statusCheckTimer !== null) {
            clearInterval(this.statusCheckTimer);
        }
    }

    renderProcessingFormGrid(processing_agreement_form, classes) {
        let disable_check_status_button = true;
        if (processing_agreement_form !== null && processing_agreement_form.hasOwnProperty('application')) {
            let elapsed_days_since_creation = 0;
            let elapsed_days_since_poll = null;
            let created_at = null;
            let last_poll = null;
            if (processing_agreement_form.application.hasOwnProperty('created_at') && processing_agreement_form.application.created_at !== null) {
                elapsed_days_since_creation = moment.utc().diff(moment.utc(processing_agreement_form.application.created_at), 'days');
                created_at = processing_agreement_form.application.created_at;
            }
            if (processing_agreement_form.application.hasOwnProperty('last_poll') && processing_agreement_form.application.last_poll !== null) {
                elapsed_days_since_poll = moment.utc().diff(moment.utc(processing_agreement_form.application.last_poll), 'minutes');
                last_poll = processing_agreement_form.application.last_poll;
            }

            disable_check_status_button = elapsed_days_since_creation <= 0
                                || (elapsed_days_since_creation >= 1 && elapsed_days_since_poll !== null && elapsed_days_since_poll < 15);

            if (disable_check_status_button) this.setStatusCheckCountdownTimer(created_at, last_poll, disable_check_status_button);
        }

        return <div>
        {
            processing_agreement_form !== null
            ? <div style={{width: "100%"}}>
                <p style={{margin: "15px 15px 0 0px"}}>
                    {
                        processing_agreement_form.signature_status === 'SIGNED'
                        ? "Signed Processing Agreement Form:"
                        : "Signature Status:"
                    }
                </p>
            </div>
            : ''
        }
        {
            processing_agreement_form !== null
            ? (
                processing_agreement_form.signature_status === 'SIGNED'
                    ? <div style={{width: "100%"}}>
                        <p style={{margin: "15px 0px"}}>
                            <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-external-link-alt"} onClick={() => {
                                return this.props.downloadDocument(processing_agreement_form.id, "Signed Processing Agreement Form.pdf");
                            }} /><span style={{cursor: "pointer"}} onClick={() => {
                                return this.props.previewDocument(processing_agreement_form.id, "Signed Processing Agreement Form.pdf");
                            }}>Signed Processing Agreement Form.pdf</span>
                        </p>
                    </div>
                    : <div style={{width: "100%"}}>
                        <table style={{margin: "15px 15px 0 0px"}}>
                            <tbody>
                                <tr>
                                    <td>Total Signatories:</td>
                                    <td>{parseInt(processing_agreement_form.application.envelope_details.progress.total)}</td>
                                </tr>
                                <tr>
                                    <td>Total Signed:</td>
                                    <td>{parseInt(processing_agreement_form.application.envelope_details.progress.signed)}</td>
                                </tr>
                                {
                                    parseInt(processing_agreement_form.application.envelope_details.progress.declined) > 0
                                    ? <tr>
                                        <td>Total Declined:</td>
                                        <td>{parseInt(processing_agreement_form.application.envelope_details.progress.declined)}</td>
                                    </tr>
                                    : null
                                }
                                {
                                    parseInt(processing_agreement_form.application.envelope_details.progress.error) > 0
                                    ? <tr>
                                        <td>Total Errored:</td>
                                        <td>{parseInt(processing_agreement_form.application.envelope_details.progress.error)}</td>
                                    </tr>
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                )
                : ''
            }
            {
                processing_agreement_form === null
                ? <Button
                    style={{margin: "15px 0px"}}
                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                    color={"success"}
                    onClick={(event) => this.props.requestProcessingSignatures(processing_agreement_form !== null, this.state.approved_signatory_type, this.state.approved_signatory_email, this.state.approved_signatory_name)}
                    >
                        Request Signature(s)
                </Button>
                : (
                    processing_agreement_form.signature_status === 'SIGNED'
                    ? ''
                    : (
                        (parseInt(processing_agreement_form.application.envelope_details.progress.declined) + parseInt(processing_agreement_form.application.envelope_details.progress.error))
                        >= 1 //parseInt(processing_agreement_form.application.envelope_details.progress.total)
                        ? <Button
                            style={{margin: "15px 0px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color={"success"}
                            onClick={(event) => this.props.requestProcessingSignatures(processing_agreement_form !== null, this.state.approved_signatory_type, this.state.approved_signatory_email, this.state.approved_signatory_name)}
                            >
                                Request New Signature(s)
                        </Button>
                        : <Button
                            style={{margin: "15px 0px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color={"success"}
                            disabled={disable_check_status_button}
                            onClick={(event) => {
                                if (disable_check_status_button) {
                                    this.props.setUsersNotification("Request signature status update disabled, please check again later. This is to prevent multiple requests.", "danger");
                                    return;
                                }
                                this.props.requestSignatureStatus(processing_agreement_form.id)
                            }}
                            >
                                Check Signature Status {this.state.checkStatusTimer.length > 0 ? <br/> : ''}{this.state.checkStatusTimer}
                        </Button>
                    )
                )
            }
        </div>
    }

    renderCreateCredentials(classes) {
        let status = this.props.renderValue('merchant_credentials_create_status');
        if (!status || status.length === 0) {
        } else if (status === 'created') {
            return <div style={{width: "100%"}}>
                <p style={{margin: "15px 15px 0 0px"}}>
                    Merchant area credentials successfully created
                </p>
            </div>;
        } else if (status === 'failed') {
            return <div style={{width: "100%"}}>
                <span style={{margin: "15px 15px 0 0px"}}>
                    <p style={{paddingTop: "1rem", margin: "0"}}>Failed to create merchant area credentials</p>
                    <Button
                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                        color={"success"}
                        onClick={(event) => this.props.requestCreateCredentials()}
                        >
                            Request New Credentials
                    </Button>
                </span>
            </div>;
        }

        return <div style={{width: "100%"}}>
            <span style={{margin: "15px 15px 0 0px"}}>
                <p style={{paddingTop: "1rem", margin: "0"}}>No merchant area credentials</p>
                <Button
                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                    color={"success"}
                    onClick={(event) => this.props.requestCreateCredentials()}
                    >
                        Request New Credentials
                </Button>
            </span>
        </div>;
    }

    getActions(prop, check_state = true) {
        if (check_state && this.state.accepts_actions.length > 0) return this.state.accepts_actions;

        let accepts_actions = prop.renderValue('accepts_actions');
        if (!accepts_actions) {
            accepts_actions = 'PAYMENT,REFUND';
        }
        return accepts_actions.split(",");
    }

    getPaymentMethods(prop, check_state = true) {
        if (check_state && this.state.gw_payment_methods.length > 0) return this.state.gw_payment_methods;
        
        let payment_methods = [];
        let value = prop.renderValue('accepted_cards_visa').toString();
        if (value.length > 0 && value === '1') {
            payment_methods.push('VISA');
        }
        value = prop.renderValue('accepted_cards_mastercard').toString();
        if (value.length > 0 && value === '1') {
            payment_methods.push('MASTERCARD');
        }
        value = prop.renderValue('accepted_cards_amex').toString();
        if (value.length > 0 && value === '1') {
            payment_methods.push('AMEX');
        }
        value = prop.renderValue('accepted_cards_diners').toString();
        if (value.length > 0 && value === '1') {
            payment_methods.push('DINERS');
        }
        value = prop.renderValue('accepted_cards_unionpay').toString();
        if (value.length > 0 && value === '1') {
            payment_methods.push('CUP');
        }
        value = prop.renderValue('accepted_cards_jcb').toString();
        if (value.length > 0 && value === '1') {
            payment_methods.push('JCB');
        }
        value = prop.renderValue('accepted_cards_discover').toString();
        if (value.length > 0 && value === '1') {
            payment_methods.push('DISCOVER');
        }
        value = prop.renderValue('accepted_cards_bankacau').toString();
        if (value.length > 0 && value === '1') {
            payment_methods.push('BANKACAU');
        }
        value = prop.renderValue('accepted_cards_bpay').toString();
        if (value.length > 0 && value === '1') {
            payment_methods.push('BPAY');
        }
        if (payment_methods.length === 0) {
            payment_methods = ['VISA', 'MASTERCARD'];
        }
        return payment_methods;
    }

    getCurrencies(prop, check_state = true) {
        if (check_state && this.state.accepts_currencies.length > 0) return this.state.accepts_currencies;

        let accepts_currencies = prop.renderValue('accepts_currencies');
        if (!accepts_currencies) {
            accepts_currencies = 'AUD';
        }
        return accepts_currencies.split(",");;
    }

    getEnabledModules(prop, check_state = true) {
        if (check_state && this.state.client_modules.length > 0) return this.state.client_modules;

        let client_modules = prop.renderValue('client_modules');
        if (!client_modules) {
            client_modules = '';
        }
        return client_modules.split(",");
    }

    renderActionButtons(key, value) {
        if (this.state.previous_status === 'approved' && this.state.current_status === 'approved') return '';
        if (!this.props.canApprove) return '';
        
        return this.state.edit_field && this.state.edit_field_key === key
            ? <span>
                <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-save"} title="Save changes" onClick={() => {
                    if (this.state.edit_field_key !== 'client_modules' && (this.state.edit_field_key_value === null || this.state.edit_field_key_value === undefined || this.state.edit_field_key_value.length === 0)) {
                        this.props.setUsersNotification("Please select an option.", "danger");
                        return;
                    }
                    this.props.saveEditedFieldFromStatus(this.state.edit_field_key, this.state.edit_field_key_value, value, () => {
                        this.setState({'edit_field': false, 'edit_field_key': '', 'edit_field_key_value': '', 'accepts_actions': '', 'gw_payment_methods': '', 'accepts_currencies': '', 'client_modules': ''});
                    });
                }} />
                <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-times"} title="Cancel edit" onClick={() => {
                    this.setState({'edit_field': false, 'edit_field_key': '', 'edit_field_key_value': ''});
                }} />
            </span>
            : <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-edit"} title="Edit this field" onClick={() => {
                let new_value = this.state.edit_field_key_value;
                let old_value = '';
                switch(this.state.edit_field_key) {
                    case 'accepts_actions':
                        old_value = this.getActions(this.props);
                        break;
                    case 'gw_payment_methods':
                        old_value = this.getPaymentMethods(this.props);
                        break;
                    case 'accepts_currencies':
                        old_value = this.getCurrencies(this.props);
                        break;
                    case 'client_modules':
                        old_value = this.getEnabledModules(this.props);
                        break;
                    default:
                        old_value = '';
                }
                if (this.state.edit_field === true && this.state.edit_field_key.length !== 0 && new_value !== old_value) {
                    this.props.setUsersNotification("Please save changes first or choose cancel edit.", "danger");
                } else {
                    this.setState({'edit_field': true, 'edit_field_key': key, 'edit_field_key_value': value})
                }
            }} />;
    }

  render () {
      const {classes} = this.props;
      let hideMessageBox = this.state.current_status && (this.state.current_status === 'awaiting_customer_response' || this.state.current_status === 'incomplete_-_please_provide_more_information');

      let processing_agreement_form = null;
      if (this.state.previous_status === 'approved') {
        try {
            this.props.client_files.forEach(file => {
                if (file.is_application === true && file.filename.startsWith(this.state.processing_form_file_name)) {
                    if (processing_agreement_form === null || processing_agreement_form.id < file.id) {
                        if (file.signature_status === 'PENDING' || file.signature_status === 'SIGNED') {
                            processing_agreement_form = file;
                        }
                    }
                }}
            );
        } catch(e) {}
      }

      return (
            <div style={{width: "100%", padding: "0px 15px 15px"}}>
                <InputLabel
                    className={classes.selectLabel}
                    htmlFor="application_status"
                >
                    Status
                </InputLabel>
                <Select
                    value={this.state.current_status ? this.state.current_status : ""}
                    onChange={(event) => this.handleChange(event.target.value)}
                    MenuProps={{"className": classes.selectMenu}}
                    classes={{"select": classes.select}}
                    inputProps={{
                        name: `application_status`,
                        id: `application_status`
                    }}
                    disabled={this.state.previous_status === 'approved'}
                    style={{width: "100%"}}
                >
                    <MenuItem value="" />
                    <MenuItem disabled={!this.allPagesAreVerified() || !this.props.canApprove} classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={1} value={'approved'}>{'Approved'}</MenuItem>
                    <MenuItem disabled={!this.allPagesAreVerified()} classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={2} value={'conditional_approved'}>{'Conditional Approved' + (!this.allPagesAreVerified() ? ' (Verify all pages)' : '')}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={3} value={'declined'}>{'Declined'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={4} value={'application_submitted'}>{'Application Submitted'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={5} value={'application_resubmitted'}>{'Application Resubmitted'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={6} value={'application_in_review'}>{'Application In Review'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={7} value={'awaiting_customer_response'}>{'Awaiting Customer Response'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={8} value={'not_suitable'}>{'Not Suitable'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={9} value={'incomplete_-_please_provide_more_information'}>{'Incomplete - Please Provide More Information'}</MenuItem>
                </Select>

                <InputLabel
                    style={{ paddingTop : "1.5rem", display: hideMessageBox ? "none" : "" }}
                    className={classes.selectLabel}
                    htmlFor="message"
                >
                    {this.state.message_text}
                </InputLabel>
                <TextField
                    style={{ display: hideMessageBox ? "none" : "" }}
                    inputProps={{
                        name: `message`,
                        id: `message`
                    }}
                    type="text"
                    className="w-100"
                    value={this.state.message ? this.state.message : ""}
                    multiline={true}
                    rows={3}
                    disabled={this.state.previous_status === 'approved'}
                    onChange={(event) => {
                        let value = event.target.value;
                        let btnDisabled = this.state.previous_status === this.state.current_status;
                        if ((this.state.current_status === 'not_suitable' || this.state.current_status === 'declined') && value.toString().length === 0) btnDisabled = true;
                        this.setState({"message": value, "btnDisabled" : btnDisabled});
                        this.props.updateChangeStatusState(this.state.current_status, this.state.additional_fields_values, value, this.state.approved_signatory_type, this.state.approved_signatory_email, this.state.approved_signatory_name);
                        this.props.handleButtonState(btnDisabled);
                    }}
                />

                {
                    this.state.current_status === 'awaiting_customer_response'
                    ? <div>
                        <InputLabel key="l1"
                            style={{ paddingTop : "1.5rem", paddingBottom: "0.5rem" }}
                            className={classes.selectLabel}
                        >
                            Request More Information
                        </InputLabel>
                        <div key="d1" style={{padding: "0 13px", border: "1px solid #8080804a"/*, maxHeight: this.props.mcc && this.props.high_risk_mccs.includes(this.props.mcc.value.toString()) ? "18vh" : "36vh", overflowY: "auto"*/}}>
                        {
                            this.props.available_fields.map((field, index) => {
                                if (field.hasOwnProperty('high_risk') && (field.high_risk === true || field.high_risk === 'true')) {
                                    return '';
                                }
                                if (field.hasOwnProperty('show_in_change_status') && !(field.show_in_change_status === true || field.show_in_change_status === 'true')) {
                                    return '';
                                }
                                return <div key={"d1" + index}>
                                    <Checkbox
                                        // style={{"margin": "2px 0px 2px 13px"}}
                                        checked={
                                            this.state.additional_fields_values.hasOwnProperty(field.registry_key)
                                                ? (this.state.additional_fields_values[field.registry_key] === 0 ? false : true) : false
                                        }
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            "checked": classes.checked,
                                            "root": classes.checkRoot
                                        }}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        onClick={(event) => {
                                            let additional_fields_values = this.state.additional_fields_values;
                                            additional_fields_values[field.registry_key] = event.target.checked ? 1 : 0;
                                            this.setState({"additional_fields_values" : additional_fields_values});
                                            this.props.updateChangeStatusState(this.state.current_status, additional_fields_values, this.state.message, this.state.approved_signatory_type, this.state.approved_signatory_email, this.state.approved_signatory_name);
                                        }}
                                        tabIndex={-1}
                                    />
                                    {field.name}</div>;
                            })
                        }
                        </div>
                        {
                            this.props.mcc && this.props.high_risk_mccs.includes(this.props.mcc.value.toString())
                            ? <div>
                                <InputLabel key="l2"
                                    style={{ paddingTop : "1.5rem", paddingBottom: "0.5rem" }}
                                    className={classes.selectLabel}
                                >
                                    High Risk Gaming Merchants
                                </InputLabel>
                                <div key="d2" style={{padding: "0 13px", border: "1px solid #8080804a"/*, maxHeight: "18vh", overflowY: "auto"*/}}>
                                {
                                    this.props.available_fields.map((field, index) => {
                                        if (!field.hasOwnProperty('high_risk') || !(field.high_risk === true || field.high_risk === 'true')) {
                                            return '';
                                        }
                                        return <div key={"d2" + index}>
                                            <Checkbox
                                                // style={{"margin": "2px 0px 2px 13px"}}
                                                checked={
                                                    this.state.additional_fields_values.hasOwnProperty(field.registry_key)
                                                        ? (this.state.additional_fields_values[field.registry_key] === 0 ? false : true) : false
                                                }
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                classes={{
                                                    "checked": classes.checked,
                                                    "root": classes.checkRoot
                                                }}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                onClick={(event) => {
                                                    let additional_fields_values = this.state.additional_fields_values;
                                                    additional_fields_values[field.registry_key] = event.target.checked ? 1 : 0;
                                                    this.setState({"additional_fields_values" : additional_fields_values});
                                                }}
                                                tabIndex={-1}
                                            />
                                            {field.name}</div>;
                                    })
                                }
                                </div>
                            </div>
                            : ''
                        }
                    </div>
                    : ''
                }
                {
                    this.state.current_status === 'incomplete_-_please_provide_more_information'
                    ? <div>
                        <InputLabel key="l2"
                            style={{ paddingTop : "1.5rem", paddingBottom: "0.5rem" }}
                            className={classes.selectLabel}
                        >
                            Request To Complete The Following
                        </InputLabel>
                        <div key="d2" style={{padding: "0 13px", border: "1px solid #8080804a"/*, maxHeight: "36vh", overflowY: "auto"*/}}>
                        {
                            this.state.request_fields === null
                            ? ""
                            : this.state.request_fields.map((field, index) => {
                                if (field.hasOwnProperty('type') && field.type === 'page_label') {
                                    return <div style={{fontWeight: 'bold'}} key={"d2" + index}>{field.name}</div>;
                                } else if (field.hasOwnProperty('type') && field.type === 'label') {
                                    let dKey = field.key.replace('request_', '');
                                    if (field.hasOwnProperty('action')) {
                                        let is_disabled = this.checkSpecialKeys(dKey, false);
                                        return <div key={"d2" + index} style={{display: is_disabled ? 'none' : 'block'}}>
                                            <Checkbox
                                                disabled={is_disabled}
                                                checked={
                                                    is_disabled
                                                    ? false
                                                    : (field.hasOwnProperty('action')
                                                        ? (this.state.selected_request_fields.includes(field.key)
                                                            ? true
                                                            : (
                                                                field.action === 'checkAllBeneficials'
                                                                ? this.areAllBeneficialsChecked(field.index, this.state.selected_request_fields).includes(field.key)
                                                                : false
                                                            ))
                                                        : this.state.selected_request_fields.includes(field.key))
                                                }
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                classes={{
                                                    "checked": classes.checked,
                                                    "root": classes.checkRoot
                                                }}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                onClick={(event) => {
                                                    let selected_request_fields = this.state.selected_request_fields;
                                                    if (event.target.checked) {
                                                        if (!selected_request_fields.includes(field.key)) {
                                                            selected_request_fields.push(field.key);
                                                        }
                                                    } else {
                                                        if (selected_request_fields.includes(field.key)) {
                                                            selected_request_fields.splice(selected_request_fields.indexOf(field.key), 1);
                                                        }
                                                    }
                                                    if (field.hasOwnProperty('action')) {
                                                        if (field.action === 'checkAllBeneficials') {
                                                            selected_request_fields = this.checkAllBeneficials(event.target.checked, field.index, selected_request_fields);
                                                        }
                                                    }
                                                    this.setState({"selected_request_fields" : selected_request_fields});
                                                    this.props.updateSelectedRequestFields(selected_request_fields);
                                                }}
                                                tabIndex={-1}
                                            />
                                            <span style={{textDecoration: is_disabled ? "line-through" : ""}}>{field.name}</span></div>;
                                    } else {
                                        let is_disabled = this.checkSpecialLabels(dKey, this.state.verified_fields);
                                        return <div style={{paddingLeft: '14px', display: is_disabled ? 'none' : 'block'}} key={"d2" + index}>{field.name}</div>;
                                    }
                                } else {
                                    let dKey = field.key.replace('request_', '');
                                    let is_disabled = this.state.verified_fields.hasOwnProperty(dKey) && parseInt(this.state.verified_fields[dKey]) === 1;
                                    is_disabled = this.checkSpecialKeys(dKey, is_disabled);
                                    return <div style={{paddingLeft: field.hasOwnProperty('action') ? '14px' : '0px', display: is_disabled ? 'none' : 'block'}} key={"d2" + index}>
                                        <Checkbox
                                            disabled={is_disabled}
                                            checked={is_disabled ? false : this.state.selected_request_fields.includes(field.key)}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            classes={{
                                                "checked": classes.checked,
                                                "root": classes.checkRoot
                                            }}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            onClick={(event) => {
                                                let selected_request_fields = this.state.selected_request_fields;
                                                if (event.target.checked) {
                                                    if (!selected_request_fields.includes(field.key)) {
                                                        selected_request_fields.push(field.key);
                                                    }
                                                } else {
                                                    if (selected_request_fields.includes(field.key)) {
                                                        selected_request_fields.splice(selected_request_fields.indexOf(field.key), 1);
                                                    }
                                                }
                                                if (field.hasOwnProperty('action')) {
                                                    if (field.action === 'areAllBeneficialsChecked') {
                                                        selected_request_fields = this.areAllBeneficialsChecked(field.index, selected_request_fields);
                                                    }
                                                }
                                                this.setState({"selected_request_fields" : selected_request_fields});
                                                this.props.updateSelectedRequestFields(selected_request_fields);
                                            }}
                                            tabIndex={-1}
                                        />
                                        <span style={{textDecoration: is_disabled ? "line-through" : ""}}>{field.name}</span></div>;
                                }
                            })
                        }
                        </div>
                    </div>
                    : ''
                }

                {
                    this.state.previous_status === 'approved'
                    ? (
                        processing_agreement_form === null || (
                            processing_agreement_form.signature_status !== 'SIGNED' &&
                            (parseInt(processing_agreement_form.application.envelope_details.progress.declined) + parseInt(processing_agreement_form.application.envelope_details.progress.error)) >= 1
                        )
                        ? <div>
                            {this.renderProcessingFormGrid(processing_agreement_form, classes)}
                        </div>
                        : this.renderProcessingFormGrid(processing_agreement_form, classes)
                    )
                    : ""
                }

                {
                this.state.previous_status === 'approved' || this.state.current_status === 'approved'
                ? <table style={{width: "100%", marginTop: "15px"}}>
                    <thead>
                        <tr>
                            <th style={{textAlign: "left"}} colSpan={3}>Merchant Area Credentials Additional Parameters</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{width: "10%", whiteSpace: "nowrap"}}>
                                Unique Name
                            </td>
                            <td colSpan={2}>
                                {
                                    this.state.previous_status === 'approved' && this.state.current_status === 'approved'
                                    ? <GridContainer key="check_name_grid">
                                        <GridItem md={6}>
                                            <CustomInput
                                                formControlProps={{"fullWidth": true}}
                                                inputProps={{
                                                    "value": this.setSignificantName(this.state.significant_name.length > 0 ? this.state.significant_name : this.getSignificantName()),
                                                    "name": "significant_name_tmp",
                                                    "disabled": true
                                                }}
                                                labelText="Unique name to be used for generating the Merchant Area Credentials"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    : ""
                                }
                                {
                                    this.state.previous_status !== 'approved' && this.state.current_status === 'approved'
                                    ? <GridContainer key="check_name_grid"><GridItem md={6}>
                                        <table style={{width: "100%"}}><tbody><tr><td>
                                            <CustomInput
                                                formControlProps={{"fullWidth": true, "style": {"margin": "0"}}}
                                                inputProps={{
                                                    "value": this.state.significant_name_edit_on ? this.state.significant_name_edited : (this.setSignificantName(this.state.significant_name.length > 0 ? this.state.significant_name : this.getSignificantName())),
                                                    "name": "significant_name",
                                                    "disabled": !this.state.significant_name_edit_on && this.state.significant_name_status !== null && this.state.significant_name_status.hasOwnProperty('in_use') && (this.state.significant_name_status.in_use === false || this.state.significant_name_status.in_use === 'false'),
                                                    "onChange": (event) => {
                                                        if (this.state.significant_name_edit_on) {
                                                            this.setState({"significant_name_edited": this.setSignificantName(event.target.value)});
                                                        } else {
                                                            this.setState({"significant_name": this.setSignificantName(event.target.value)});
                                                        }
                                                    },
                                                }}
                                                labelText="Please add a unique name to be used for generating the Merchant Area Credentials"
                                            />
                                        </td><td style={{width: "45px", whiteSpace: "nowrap"}}>
                                            {
                                                this.state.significant_name_edit_on || this.state.significant_name_status === null || !(this.state.significant_name_status !== null && this.state.significant_name_status.hasOwnProperty('in_use') && (this.state.significant_name_status.in_use === false || this.state.significant_name_status.in_use === 'false'))
                                                ? <span style={{cursor: "pointer", paddingRight: "5px"}}
                                                    onClick={(event) => this.props.checkSignificantName(
                                                        this.state.significant_name_edit_on
                                                            ? this.setSignificantName(this.state.significant_name_edited)
                                                            : this.setSignificantName(this.state.significant_name.length > 0 ? this.state.significant_name : this.getSignificantName()),
                                                        (response) => {
                                                            if (response.hasOwnProperty('in_use') && (response.in_use === false || response.in_use === 'false')) {
                                                                let value = this.state.significant_name_edit_on
                                                                    ? this.setSignificantName(this.state.significant_name_edited)
                                                                    : this.setSignificantName(this.state.significant_name.length > 0 ? this.state.significant_name : this.getSignificantName());
                                                                this.setState({'significant_name_edit_on': false, 'significant_name_edited': value, 'significant_name': value});
                                                            }
                                                            this.setState({"significant_name_status": response});
                                                        }
                                                    )}
                                                >
                                                    <i title={"Check if name is available"} className={"fas fa-search"} style={{fontSize: "0.9rem", marginRight: "0.1rem"}}/>
                                                </span>
                                                : ""
                                            }
                                            {
                                                this.state.significant_name_status === null
                                                ? ""
                                                : (this.state.significant_name_edit_on
                                                    ? ""
                                                    : <span style={{cursor: "pointer", paddingRight: "5px"}}>
                                                        <i className={"fas fa-check-circle"} style={{fontSize: "0.9rem", marginRight: "0.1rem", color: this.state.significant_name_status.hasOwnProperty('in_use') && (this.state.significant_name_status.in_use === false || this.state.significant_name_status.in_use === 'false') ? 'green' : 'red'}}/>
                                                    </span>)
                                            }
                                            {
                                                this.state.significant_name_status !== null && this.state.significant_name_status.hasOwnProperty('in_use') && (this.state.significant_name_status.in_use === false || this.state.significant_name_status.in_use === 'false')
                                                ? (this.state.significant_name_edit_on
                                                    ? <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-times"} title="Cancel edit" onClick={() => {
                                                        this.setState({
                                                            'significant_name_edit_on': false,
                                                            'significant_name_edited': this.setSignificantName(this.state.significant_name.length > 0 ? this.state.significant_name : this.getSignificantName())
                                                        })
                                                    }} />
                                                    : <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-edit"} title="Edit this field" onClick={() => {
                                                        this.setState({
                                                            'significant_name_edit_on': true,
                                                            'significant_name_edited': this.setSignificantName(this.state.significant_name.length > 0 ? this.state.significant_name : this.getSignificantName())
                                                        });
                                                    }} />
                                                )
                                                : ""
                                            }
                                        </td></tr></tbody></table>
                                    </GridItem></GridContainer>
                                    : ""
                                }
                            </td>
                        </tr>
                        <tr>
                            <td style={{whiteSpace: "nowrap"}}>
                                Actions
                            </td>
                            <td>
                                <Select
                                    style={{width: "100%"}}
                                    classes={{"select": classes.select}}
                                    inputProps={{
                                        "name": "accepts_actions",
                                        "id": "accepts_actions"
                                    }}
                                    multiple={true}
                                    MenuProps={{"className": classes.selectMenu}}
                                    onChange={(event) => {
                                        if (this.state.edit_field && this.state.edit_field_key === 'accepts_actions' ) {
                                            this.setState({"edit_field_key_value": event.target.value});
                                        } else {
                                            this.setState({"accepts_actions": event.target.value});
                                        }
                                    }}
                                    value={this.state.edit_field && this.state.edit_field_key === 'accepts_actions' ? this.state.edit_field_key_value : this.getActions(this.props)}
                                    disabled={this.state.edit_field && this.state.edit_field_key === 'accepts_actions' ? false : true}
                                    title={this.state.edit_field && this.state.edit_field_key === 'accepts_actions' ? this.state.edit_field_key_value : this.getActions(this.props)}
                                >
                                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'accepts_actions_0'} value="NON" disabled={true}>Choose Actions</MenuItem>
                                    {
                                        ['PAYMENT','REFUND','SECUREPAYMENT','DIRECTDEBIT','DIRECTCREDIT','AUTHORISATION','CAPTURE'/*,'PAYOUT','NPPPAYOUT','NPPPAYMENT'*/].map((action) => {
                                            return <MenuItem
                                                classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}}
                                                key={'accepts_actions_' + action}
                                                value={action}
                                            >
                                                {action}
                                            </MenuItem>;
                                        })
                                    }
                                </Select>
                            </td>
                            <td style={{whiteSpace: "nowrap", width: "35px"}}>
                                {
                                    this.renderActionButtons('accepts_actions', this.getActions(this.props))
                                }
                            </td>
                        </tr>
                        <tr>
                            <td style={{whiteSpace: "nowrap"}}>
                                Payment Methods
                            </td>
                            <td>
                                <Select
                                    style={{width: "100%"}}
                                    classes={{"select": classes.select}}
                                    inputProps={{
                                        "name": "gw_payment_methods",
                                        "id": "gw_payment_methods"
                                    }}
                                    multiple={true}
                                    MenuProps={{"className": classes.selectMenu}}
                                    onChange={(event) => {
                                        if (this.state.edit_field && this.state.edit_field_key === 'gw_payment_methods' ) {
                                            this.setState({"edit_field_key_value": event.target.value});
                                        } else {
                                            this.setState({"gw_payment_methods": event.target.value});
                                        }
                                    }}
                                    value={this.state.edit_field && this.state.edit_field_key === 'gw_payment_methods' ? this.state.edit_field_key_value : this.getPaymentMethods(this.props)}
                                    disabled={this.state.edit_field && this.state.edit_field_key === 'gw_payment_methods' ? false : true}
                                    title={this.state.edit_field && this.state.edit_field_key === 'gw_payment_methods' ? this.state.edit_field_key_value : this.getPaymentMethods(this.props)}
                                >
                                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'gw_payment_methods_0'} value="NON" disabled={true}>Choose Payment Methods</MenuItem>
                                    {
                                        ['VISA','MASTERCARD','AMEX','DINERS','CUP','JCB','DISCOVER','BANKACAU','BPAY'].map((method) => {
                                            return <MenuItem
                                                classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}}
                                                key={'gw_payment_methods_' + method}
                                                value={method}
                                            >
                                                {method}
                                            </MenuItem>;
                                        })
                                    }
                                </Select>
                            </td>
                            <td style={{whiteSpace: "nowrap"}}>
                                {
                                    this.renderActionButtons('gw_payment_methods', this.getPaymentMethods(this.props))
                                }
                            </td>
                        </tr>
                        <tr>
                            <td style={{whiteSpace: "nowrap"}}>
                                Currencies
                            </td>
                            <td>
                                <Select
                                    style={{width: "100%"}}
                                    classes={{"select": classes.select}}
                                    inputProps={{
                                        "name": "accepts_currencies",
                                        "id": "accepts_currencies"
                                    }}
                                    multiple={true}
                                    MenuProps={{"className": classes.selectMenu}}
                                    onChange={(event) => {
                                        if (this.state.edit_field && this.state.edit_field_key === 'accepts_currencies' ) {
                                            this.setState({"edit_field_key_value": event.target.value});
                                        } else {
                                            this.setState({"accepts_currencies": event.target.value});
                                        }
                                    }}
                                    value={this.state.edit_field && this.state.edit_field_key === 'accepts_currencies' ? this.state.edit_field_key_value : this.getCurrencies(this.props)}
                                    disabled={this.state.edit_field && this.state.edit_field_key === 'accepts_currencies' ? false : true}
                                    title={this.state.edit_field && this.state.edit_field_key === 'accepts_currencies' ? this.state.edit_field_key_value : this.getCurrencies(this.props)}
                                >
                                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'accepts_currencies_0'} value="NON" disabled={true}>Choose Currencies</MenuItem>
                                    {
                                        [
                                            {'key': 'AUD', 'value': 'Australian Dollar(AUD)'},
                                            {'key': 'USD', 'value': 'US Dollar(USD)'},
                                            {'key': 'CAD', 'value': 'Canadian Dollar(CAD)'},
                                            {'key': 'GBP', 'value': 'Pound Sterling(GBP)'},
                                            {'key': 'EUR', 'value': 'Euro(EUR)'}
                                        ].map((currency) => {
                                            return <MenuItem
                                                classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}}
                                                key={'accepts_currencies_' + currency.key}
                                                value={currency.key}
                                            >
                                                {currency.value}
                                            </MenuItem>;
                                        })
                                    }
                                </Select>
                            </td>
                            <td style={{whiteSpace: "nowrap"}}>
                                {
                                    this.renderActionButtons('accepts_currencies', this.getCurrencies(this.props))
                                }
                            </td>
                        </tr>
                        <tr>
                            <td style={{whiteSpace: "nowrap"}}>
                                Enabled Modules
                            </td>
                            <td>
                                <Select
                                    style={{width: "100%"}}
                                    classes={{"select": classes.select}}
                                    inputProps={{
                                        "name": "client_modules",
                                        "id": "client_modules"
                                    }}
                                    multiple={true}
                                    MenuProps={{"className": classes.selectMenu}}
                                    onChange={(event) => {
                                        if (this.state.edit_field && this.state.edit_field_key === 'client_modules' ) {
                                            this.setState({"edit_field_key_value": event.target.value});
                                        } else {
                                            this.setState({"client_modules": event.target.value});
                                        }
                                    }}
                                    value={this.state.edit_field && this.state.edit_field_key === 'client_modules' ? this.state.edit_field_key_value : this.getEnabledModules(this.props)}
                                    disabled={this.state.edit_field && this.state.edit_field_key === 'client_modules' ? false : true}
                                    title={this.state.edit_field && this.state.edit_field_key === 'client_modules' ? this.state.edit_field_key_value : this.getEnabledModules(this.props)}
                                >
                                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'client_modules_0'} value="NON" disabled={true}>Choose Modules</MenuItem>
                                    {
                                        [
                                            {'key': 'vt_enabled', 'value': 'Virtual Terminal'},
                                            {'key': 'app_enabled', 'value': 'App Config'},
                                            {'key': 'statement_enabled', 'value': 'Statements'},
                                            {'key': 'customers_enabled', 'value': 'Customers'},
                                            {'key': 'hsp_enabled', 'value': 'Hosted Sales'},
                                            {'key': 'recur_trans_enabled', 'value': 'Recurring Transactions'},
                                            {'key': 'subuser_enabled', 'value': 'SubUser'},
                                            {'key': 'apple_pay_enabled', 'value': 'Apple Pay'},
                                            {'key': 'payout_enabled', 'value': 'Payout Transaction'},
                                            {'key': 'surcharging_enabled', 'value': 'Surcharging'},
                                            {'key': 'npp_payout_terminal_enabled', 'value': 'NPP Payout Terminal'},
                                            {'key': 'azupay_clients', 'value': 'Azupay Clients'}
                                        ].map((client_module) => {
                                            return <MenuItem
                                                classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}}
                                                key={'client_modules_' + client_module.key}
                                                value={client_module.key}
                                            >
                                                {client_module.value}
                                            </MenuItem>;
                                        })
                                    }
                                </Select>
                            </td>
                            <td style={{whiteSpace: "nowrap"}}>
                                {
                                    this.renderActionButtons('client_modules', this.getEnabledModules(this.props))
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
                : ""
                }

                {
                    this.state.previous_status === 'approved' && processing_agreement_form !== null && processing_agreement_form.signature_status === 'SIGNED'
                    ? this.renderCreateCredentials(classes)
                    : ""
                }
            </div>
      );
  }
}

export default withStyles(extendedFormsStyle)(ChangeApplicationStatusDialog);
